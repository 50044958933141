<template>
  <b-row>
    <b-col>

      <b-row  align-h="end">
        <b-col  lg="8" xl="6" class="ml-md-auto p-3">
          <b-form-checkbox
              id="checkbox-1"
              v-model="filters.withText"
              name="checkbox-1"
              switch inline
          >
            Только с текстом
          </b-form-checkbox>

          <b-form-checkbox
              id="checkbox-2"
              v-model="filters.withPhoto"
              name="checkbox-2" switch inline
          >
            Только с фото
          </b-form-checkbox>

          <b-form-checkbox
              id="checkbox-3"
              v-model="filters.onlyQ"
              name="checkbox-3" switch inline
          >
            Только вопросы
          </b-form-checkbox>

          <b-form-checkbox
              id="checkbox-4"
              v-model="filters.withoutAnswer"
              name="checkbox-4" switch inline
          >
            Просроченные без ответа
          </b-form-checkbox>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
        <b-col md="2">Всего: {{totalRows}}</b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
              :items="feedbacksListFiltered"
              :fields="fields"
              :per-page="perPage"
              :current-page="curPage"
              show-empty
              small
              headVariant="light"
              bordered
              responsive
              stacked="md"
              :busy="isLoading"
              tbody-class="acuvue-table"
              style="width: 99%"
              thead-class="acuvue-thead"
          >
            <template #table-busy>
              <div class="text-center text-secondary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #empty="">
              <div role="alert" aria-live="polite"><div class="text-center my-2">Список пуст.</div></div>
            </template>

            <template #cell(createdDate)="{item}">
              {{dayjs(item.createdDate).format("DD.MM.YYYY HH:mm:ss")}}
            </template>
            <template #cell(feedback)="{item}">
              <a :href="`https://www.wildberries.ru/catalog/${item.productDetails.nmId}/detail.aspx`" target="_blank"><b>{{item.productDetails.productName}}</b>
              <span v-if="item.productDetails.supplierName=='Берлов Константин Игоревич ИП'"> | ИП</span>
              <span v-if='item.productDetails.supplierName.indexOf("НЕОВИЖН")+1'> | НЕО</span>
              <span v-if="item.productDetails.supplierName.indexOf('ВИП')+1"> | ВИП</span>
              </a><br>
              {{item.text || 'без текста'}} <br>
              <b-img thumbnail fluid left v-for="(mini,indx) of item.photoLinks" :src="mini.miniSize" alt="" :key="`photo-${item.productDetails.nmId}-${indx}`"></b-img>
<!--              <b-img thumbnail fluid left src="https://feedback03.wb.ru/vol1154/part115491/115491476/photos/ms.jpg" alt=""></b-img>-->
<!--              <b-img thumbnail fluid left src="https://feedback03.wb.ru/vol1154/part115491/115491476/photos/ms.jpg" alt=""></b-img>-->

            </template>

            <template #cell(productValuation)="{item}">
              <b-form-rating v-if="item.productValuation>0" disabled inline no-border v-model="item.productValuation" ></b-form-rating>
              <span v-else>вопрос</span>
            </template>
            <template #cell(answer)="{item}">
              <template v-if="item.answer">
                <b-badge v-if="item.answer.state=='wbRu'" variant="success">Опубликован</b-badge>
                <b-badge v-if="item.answer.state=='none' && item.productValuation>0" variant="info">Новый</b-badge>
                <b-badge v-if="item.answer.state=='none' && item.productValuation<=0" variant="warning">Вопрос отклонён продавцом</b-badge>
                <b-badge v-if="item.answer.state=='reviewRequired'" variant="warning">На проверке</b-badge>
                <b-badge v-if="item.answer.state=='rejected'" variant="danger">Отклонен</b-badge>
                <br>
                {{item.answer.text || 'без ответа'}}
              </template>
              <template v-else>
                <b-badge variant="danger">Не успели ответить</b-badge>
              </template>


            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="curPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              limit="15"
          ></b-pagination>

        </b-col>
      </b-row>

    </b-col>


  </b-row>

</template>

<style scoped>
.b-rating.disabled, .b-rating:disabled {
  background-color:transparent;
  color: #6c757d;
}
a {
  color: #111;
  font-weight: bold;
}
div {
  font-size: 12px;
}
</style>

<script>
// import _ from "lodash"
import dayjs from "dayjs";

// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "wb-feedbacks-archive",
  components: { },
  data() {
    return {
      feedbacksList: [],
      fields: [
        { key: 'createdDate', label: 'Дата создания отзыва', sortable: true, sortDirection: 'desc' },
        { key: 'feedback', label: 'Отзыв', sortable: false, sortDirection: 'desc' },
        { key: 'productValuation', label: 'Оценка', sortable: true, sortDirection: 'desc' },
        { key: 'answer', label: '', sortable: false, sortDirection: 'desc' }
      ],
      isLoading: false,
      curPage: 1,
      totalRows: 0,
      perPage: 100,
      filters: {
        withText: false,
        withPhoto: false,
        onlyQ:false,
        withoutAnswer:false
      },
      wbTemplates:[]

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getArchive()


  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    hideRow(id) {
      // console.log('hide row')
      // console.log(id)
      this.alertMsg('Отправить ответ | Успешно', `ok ${id}`, 'success')
      this.feedbacksList = this.feedbacksList.filter(e=>e.id!=id)
    },
    getArchive() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wb-feedbacks/getArchive`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.feedbacksList = resp.items
              this.isLoading=false
              this.totalRows=resp.items.length
            }
            // if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },

  },
  computed: {
    dayjs() {
      return dayjs
    },
    feedbacksListFiltered: function () {
      let result = this.feedbacksList //.slice(0,5)

      if (this.filters.withPhoto) result = result.filter(e=>e.photoLinks)
      if (this.filters.withText) result = result.filter(e=>e.text)
      if (this.filters.onlyQ) result = result.filter(e=>e.productValuation==0)
      if (this.filters.withoutAnswer) result = result.filter(e=>!e.answer)


      return result
    },
  },
  watch:{
    'feedbacksListFiltered': function () {
      this.totalRows=this.feedbacksListFiltered.length
    }
  }
};
</script>